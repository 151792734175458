//console.log('>> Home page');
//import $ from 'jquery';
import '@glidejs/glide/dist/glide.min.js';
import * as AOS from 'aos/dist/aos.js';
import './modals';
import './countup';
//import './collapsible';
//import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'
import L from 'leaflet';

AOS.init();

window.addEventListener('scroll', function () {
  var body = document.body;
  if (window.scrollY > 0) {
    body.classList.add('scrolled');
  } else {
    body.classList.remove('scrolled');
  }
});
/*
document.addEventListener("DOMContentLoaded", function () {
  const navIcon = document.getElementById("nav-icon");
  //const navWrapper = document.getElementById("navigation");
  const navContent = document.getElementById("nav");
  const navItems = document.querySelectorAll(".nav-item");

  function toggleOpenClass() {
    navIcon.classList.toggle("open");
    //navWrapper.classList.toggle("open");
    navContent.classList.toggle("open");

    navItems.forEach(function (item) {
      item.classList.toggle("open");
    });
  }

  navIcon.addEventListener("click", toggleOpenClass);
  navItems.forEach(function (item) {
    item.addEventListener("click", toggleOpenClass);
  });
});
*/

// glide.js 
document.addEventListener('DOMContentLoaded', function () {
  var leistungenSliderElement = document.querySelector('.leistungen__slider');

  if (leistungenSliderElement) {
    // Die Klasse ".leistungen__slider" wurde im DOM gefunden
    var leistungenslider = new Glide(leistungenSliderElement, {
      type: 'carousel',
      autoplay: 5000,
      perView: 4,
      dots: '#dots',
      gap: 20,

      breakpoints: {
        1024: {
          perView: 2
        },
        768: {
          perView: 2
        }
      }
    });
    leistungenslider.mount();
  }
});

document.addEventListener('DOMContentLoaded', function () {
  var schwerpunkteSliderElement = document.querySelector('.schwerpunkte__slider');

  if (schwerpunkteSliderElement) {
    // Die Klasse "..schwerpunkte__slider" wurde im DOM gefunden
    var schwerpunktslider = new Glide(schwerpunkteSliderElement, {
      type: 'carousel',
      autoplay: 5000,
      perView: 4,
      dots: '#dots',
      gap: 20,
      autoheight: true,
      breakpoints: {
        1024: {
          perView: 2
        },
        768: {
          perView: 2
        }
      }
    });
    schwerpunktslider.mount();
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const header = document.querySelector('.headerslider');
  if (header) {
    var headerslider = new Glide(header, {
      type: 'carousel',
      autoplay: 3500,
      perView: 1,
      gap: 0,
      animationTimingFunc: 'ease-in-out',
      animationDuration: 800,
    });
    headerslider.mount();
  }
});


// Navbar Button toggle
const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('navbar__nav');

menuToggle.addEventListener('click', () => {
  navbar.classList.toggle('open');
});


// team show more
/*
document.addEventListener("DOMContentLoaded", function () {
  var teamContainer = document.querySelector('#team');

  if (teamContainer) {
    const items = document.querySelectorAll(".team__item");
    const showMoreButton = document.getElementById("showMore");
    let visibleItemCount = 4;
    for (let i = 0; i < 4; i++) {
      items[i].style.opacity = 1; // Setze die Opazität der ersten 4 Elemente auf 1
    }
    // Verstecke anfangs die Elemente 5 bis 10
    for (let i = visibleItemCount; i < items.length; i++) {
      items[i].classList.add("hidden");
    }
    showMoreButton.addEventListener("click", function () {
      for (let i = visibleItemCount; i < visibleItemCount + 4 && i < items.length; i++) {
        items[i].style.opacity = 1;
        items[i].style.display = "flex";
      }
      visibleItemCount += 4;

      if (visibleItemCount >= items.length) {
        showMoreButton.style.display = "none";
      }
    });
  }
});
*/
/*
document.addEventListener("DOMContentLoaded", function () {
  const items = document.querySelectorAll(".team__item");
  const showMoreButton = document.getElementById("showMore");

  for (let i = 4; i < items.length; i++) {
      items[i].classList.add("hidden");
  }

  showMoreButton.addEventListener("click", function () {
      for (let i = 4; i < items.length; i++) {
          items[i].classList.remove("hidden");
      }
      showMoreButton.style.display = "none";
  });
});
*/


// ScrollTo

document
  .querySelectorAll('a.scroll-to')
  .forEach(trigger => {
    trigger.onclick = function (e) {
      e.preventDefault();

      let hash = this.getAttribute('href').split('#')[1];
      let target = document.getElementById(hash);

      if (!target) {
        window.location.href = this.href;
        return;
      }

      let headerOffset = parseInt(this.getAttribute('data-header-offset')) || 175;
      let elementPosition = target.offsetTop;
      let offsetPosition = elementPosition - headerOffset;
      //console.log(offsetPosition);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    };
  });


/* leaflet map */
document.addEventListener('DOMContentLoaded', function () {
  var mapContainer = document.querySelector('#map');

  if (mapContainer) {
    var mapIcon = L.icon({
      iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',

      //shadowUrl: 'leaf-shadow.png',
      iconSize: [25, 41], // size of the icon
      //shadowSize:   [50, 64], // size of the shadow
      //iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
      //shadowAnchor: [4, 62],  // the same for the shadow
      //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    var mymap = L.map('map').setView([51.217995834375216, 6.775607827099035], 15);
    var x = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png').addTo(mymap);
    marker1 = L.marker([51.217995834375216, 6.775607827099035], { icon: mapIcon }).addTo(mymap).bindPopup("<div class='umap-popup-container'><p><strong>EICKHOFF Personal </strong><br>Elisabethstr. 3, 40217 Düsseldorf</p></div>");
  }
});

// Navbar Dropdown
document.addEventListener('DOMContentLoaded', function () {
  var dropdownLinks = document.querySelectorAll('[data-dropdown]');
  var dropdownLinksInner = document.querySelectorAll('.dropdown-link');

  dropdownLinks.forEach(function (link) {
    link.addEventListener('click', function () {
      var dropdownId = link.getAttribute('data-dropdown');
      var dropdownMenu = document.getElementById(dropdownId);

      if (dropdownMenu) {
        dropdownMenu.classList.toggle('hidden');
      }

      dropdownLinksInner.forEach(function (link) {
        link.addEventListener('click', function () {
          dropdownMenu.classList.toggle('hidden');
        });
      });

    });
  });


});